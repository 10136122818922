import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { z, ZodSchema } from "zod";
import { formatZodError } from "~/utils/tsUtils";
import {
  ApiResponse,
  createErrorResponse,
  createSuccessResponse,
  getErrorFromAxiosException,
  HttpHeaders,
  Path,
  ReqData,
  HEADERS,
  RequestParams,
} from "./utils";
import { config } from "~/config"

const executeRequest = (
  method: Method,
  path: Path,
  data: ReqData | undefined,
  customHeader: HttpHeaders = {},
  params?: RequestParams
): Promise<AxiosResponse<unknown>> => {
  const token = window.localStorage.getItem("token");

  return axios({
    url: `${config.API_BASE_URL}${path}`,
    method,
    headers: { ...{ token }, ...HEADERS, ...customHeader },
    validateStatus: (status: number) => status < 500,
    data,
    params,
  });
};

const ErrorBodySchema = z.object({
  message: z.string(),
});

const validateResponse = <SchemaOutput, SchemaInput>(
  response: AxiosResponse<unknown>,
  responseSchema: ZodSchema<SchemaOutput, any, SchemaInput>,
  expectedCode: number
): ApiResponse<SchemaOutput> => {
  if (response.status !== expectedCode) {

    console.log('response', response)
    // If the API returns us an error message, we should return it
    const parsedErrorBody = ErrorBodySchema.safeParse(response.data);
    if (parsedErrorBody.success) {
      return createErrorResponse({
        status: response.status,
        errorMessage: parsedErrorBody.data.message,
      });
    }

    // If the API doesn't return an error message, we return a generic error message
    return createErrorResponse({
      status: response.status,
      errorMessage: `Expected status code ${expectedCode}, but got ${response.status
        } with body: ${JSON.stringify(response.data)}`,
    });
  }

  const validatedData = responseSchema.safeParse(response.data);
  if (!validatedData.success) {
    return createErrorResponse({
      errorMessage: `Error while parsing response data: ${formatZodError(
        validatedData.error
      )}`,
      status: 500,
    });
  }

  return createSuccessResponse(validatedData.data);
};

export const executeAndValidateRequest = async <SchemaOutput, SchemaInput>(
  method: Method,
  path: Path,
  data: ReqData | undefined,
  responseSchema: ZodSchema<SchemaOutput, any, SchemaInput>,
  expectedCode: number,
  customHeader: HttpHeaders = {},
  params?: RequestParams
): Promise<ApiResponse<SchemaOutput>> => {
  let response: AxiosResponse<unknown>;
  try {
    response = await executeRequest(method, path, data, customHeader, params);
  } catch (e) {
    return getErrorFromAxiosException(e);
  }

  return validateResponse(response, responseSchema, expectedCode);
};
